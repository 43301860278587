import { RESET_FILTERS, SET_FILTERS } from '../actions/types';
import { threeMonthsAgoDate } from '../helpers/date';

const INITIAL_STATE = {
  inUse: false,
  type: null,
  start: threeMonthsAgoDate,
  end: new Date(),
  vendors: null,
  filterSwitch: false,
  integration: null,
};

export default function filterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_FILTERS:
      return {
        ...INITIAL_STATE,
        inUse: true,
      };
    case SET_FILTERS:
      return {
        ...state,
        ...action.payload,
        inUse: true,
      };
    default:
      return state;
  }
}
