/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */

import moment from 'moment';
import { vendors } from '../constants/clouds';
import { USD_EXCHANGE_RATE } from '../constants/exchangesRate';
// eslint-disable-next-line import/no-cycle

const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const today = new Date();

const serverCompatibleFormat = 'YYYY-MM-DDT00:00:00.000+00:00';
export const threeMonthsAgoDate = moment(new Date())
  .add(-3, 'months')
  .startOf('month')
  .format(serverCompatibleFormat);
// eslint-disable-next-line max-len
export const getStartMonth = (prevMonths = 1) =>
  new Date(today.getFullYear(), today.getMonth() - prevMonths, 1);

export const parseToExactRegionDate = (date, substractAMonth = false) => {
  const corruptedDate = new Date(date);

  if (substractAMonth) {
    corruptedDate.setMonth(corruptedDate.getMonth() - 1);
  }
  // eslint-disable-next-line max-len
  const originalDate = new Date(
    corruptedDate.getTime() - corruptedDate.getTimezoneOffset() * 60000,
  ).toISOString();
  return originalDate;
};

export const lastMonth = () => ({
  start: new Date(today.getFullYear(), today.getMonth() - 1, 1)
    .toISOString()
    .split('T')[0],
  end: new Date(today.getFullYear(), today.getMonth(), 1)
    .toISOString()
    .split('T')[0],
});

export const getMonthNameBefore = (months = 0) =>
  new Date(
    today.getFullYear(),
    new Date().getMonth() - months,
    today.getDay(),
  ).toLocaleString('en-US', {
    month: 'long',
  });

const groupExpensesByMonth = (expenses) => {
  const groupedByMonths = { ...expenses };
  const vendorNames = Object.keys(expenses);
  vendorNames.forEach((vendor) => {
    groupedByMonths[vendor] = expenses[vendor].reduce((acc, expense) => {
      const date = moment(expense?.invoiceTimestamp?.start);

      const nextMonth = date.add(1, 'months').month();

      const targetMonth = MonthNames[nextMonth];

      if (!acc[targetMonth]) {
        acc[targetMonth] = [];
      }
      acc[targetMonth].push({ ...expense, targetMonth });
      return acc;
    }, {});
  });
  return groupedByMonths;
};

const getTotalByMonth = (groupedExpenses) => {
  const totalPlusingCosts = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key1, cloudVendors] of Object.entries(groupedExpenses)) {
    totalPlusingCosts[key1] = {};
    for (const [key2, expenses] of Object.entries(cloudVendors)) {
      const totalSpend = expenses.reduce((acc, expense) => {
        if (expense.currencyUnit !== USD_EXCHANGE_RATE) {
          return acc + expense.exchangeRate[USD_EXCHANGE_RATE] * expense.amount;
        }

        return acc + expense.amount;
      }, 0);
      totalPlusingCosts[key1][key2] = totalSpend;
    }
  }
  return totalPlusingCosts;
};

const getLastMonthsTotalPlusingCosts = (
  totalSpendingsByMonth,
  originMonth,
  observableMonths,
) => {
  const selectedMonths = getPreviousMonths(originMonth, observableMonths);

  const calculationByProvider = [];
  const currentMonthSpending = [];
  const backgroundColors = ['#14192d', '#7c69ef', '#9a90ee', '#14192d'];

  Object.entries(totalSpendingsByMonth).forEach(([vendor, monthData]) => {
    const baseMonthData = {};
    selectedMonths.forEach((month) => {
      baseMonthData[month] = 0;
    });
    // eslint-disable-next-line max-len
    const filteredMonthData = Object.fromEntries(
      Object.entries(monthData).filter(([month]) =>
        selectedMonths.includes(month),
      ),
    );

    // eslint-disable-next-line operator-linebreak
    calculationByProvider[vendor] =
      Object.keys(filteredMonthData).length === 0
        ? baseMonthData
        : filteredMonthData;
  });

  const groupedByMonth = Object.keys(calculationByProvider).reduce(
    (acc, cloudService) => {
      Object.keys(calculationByProvider[cloudService]).forEach((month) => {
        if (!acc[month]) {
          acc[month] = {};
        }
        acc[month][cloudService] = calculationByProvider[cloudService][month];
      });
      return acc;
    },
    {},
  );

  const sortedMonths = {};
  selectedMonths.forEach((key) => {
    sortedMonths[key] = groupedByMonth[key];
  });

  Object.values(vendors).forEach((vendor, idx) => {
    const monthsDataset = [];
    Object.entries(sortedMonths).forEach(([month, monthData], idx) => {
      monthsDataset.push(sortedMonths[month][vendor]);
    });

    currentMonthSpending.push({
      label: selectedMonths[idx],
      data: monthsDataset,
      backgroundColor: backgroundColors[idx],
    });
  });

  const cleanSpendings = currentMonthSpending.filter(
    (month) => month.label !== undefined,
  );

  return {
    // eslint-disable-next-line max-len
    currentMonthSpending: cleanSpendings,
    monthNames: cleanSpendings.map((item) => item.label),
    vendorsNames: Object.keys(calculationByProvider),
    colors: backgroundColors,
  };
};

const getPreviousMonths = (originMonthName, monthLimit) => {
  const originMonthIndex = MonthNames.indexOf(originMonthName);
  const previousFiveMonths = [];
  for (
    let i = originMonthIndex - 1;
    i >= Math.max(0, originMonthIndex - monthLimit);
    i--
  ) {
    previousFiveMonths.unshift(MonthNames[i]);
  }

  if (previousFiveMonths.length < monthLimit) {
    const foundMonths = previousFiveMonths.length;
    const missingMonthsSize = foundMonths - monthLimit;

    previousFiveMonths.unshift(...MonthNames.slice(missingMonthsSize));
  }

  return previousFiveMonths;
};

const groupExpensesByVendors = (expenses) =>
  expenses.reduce((acc, expense) => {
    Object.entries(vendors).forEach((vendor) => {
      if (!acc[vendor[1]]) {
        acc[vendor[1]] = [];
      }
    });

    if (acc[vendors.AWS] && expense.vendorName === vendors.AWS) {
      acc[vendors.AWS].push({ ...expense });
    }
    if (acc[vendors.Azure] && expense.vendorName === vendors.Azure) {
      acc[vendors.Azure].push({ ...expense });
    }
    if (acc[vendors.GCP] && expense.vendorName === vendors.GCP) {
      acc[vendors.GCP].push({ ...expense });
    }
    if (acc[vendors.OCI] && expense.vendorName === vendors.OCI) {
      acc[vendors.OCI].push({ ...expense });
    }
    return acc;
  }, {});

export const getLastMonthsGroupedExpenses = (expenses, observableMonths) => {
  if (!expenses?.length) {
    return null;
  }

  const currentDate = new Date();
  const currentMonth = MonthNames[currentDate.getMonth()];
  const groupedByVendors = groupExpensesByVendors(expenses);

  const groupedExpenses = groupExpensesByMonth(groupedByVendors);
  const calculatedByMonth = getTotalByMonth(groupedExpenses);

  return getLastMonthsTotalPlusingCosts(
    calculatedByMonth,
    currentMonth,
    observableMonths,
  );
};
