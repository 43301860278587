import {
  FEED_MODAL,
  FETCH_DEFAULT_ORGANIZATION,
  FETCH_ORGANIZATION_EDGES,
  FETCH_ORGANIZATION_INSIGHT,
  FETCH_ORGANIZATION_INVITATIONS,
  FETCH_ORGANIZATION_MEMBERS,
  FETCH_ORGANIZATIONS,
  SET_ORGANIZATION_PERSONAL_FLAG,
  FETCH_ORGANIZATION_AFFILIATES,
} from '../types';
import hypercube from '../../api/hypercube';
import { fetchIntegrations, fetchIntegrationsEdges } from '../dashboard/integrationsActions';
import {
  fetchLatestNotifications,
  fetchNotifications,
  fetchNotificationsEdges,
} from '../dashboard/notificationsActions';
import { fetchSavings } from '../dashboard/savingsActions';
import { fetchSecurities } from '../dashboard/securityActions';
import { fetchAccounts, fetchThreatsActive, fetchThreatsIdentified } from '../dashboard/widgetsActions';
import { fetchSpendingTotal, fetchTransactions } from '../spending/transactionsActions';
import { reloadUser } from '../userActions';
import { threeMonthsAgoDate } from '../../helpers/date';
import { fetchVendors } from '../dashboard/vendorActions';

export const addOrganization = (properties) => async (dispatch) => {
  try {
    const requestString = '/organizations';
    const { name, description, brandLogoUrl } = properties;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('brandLogoUrl', brandLogoUrl);

    await hypercube.post(requestString, formData);

    dispatch({ type: FEED_MODAL, payload: { type: 'success' } });
    dispatch(fetchOrganizations({}));
    dispatch(reloadUser());
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot create organizations at the moment',
        err,
      },
    });
  }
};

export const fetchOrganizationInsights = (organizationId) => async (dispatch) => {
  try {
    const requestString = `/organizations/${organizationId}/insights`;
    const { data } = await hypercube.get(requestString);
    dispatch({ type: FETCH_ORGANIZATION_INSIGHT, payload: data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot get organization insights at the moment',
        err,
      },
    });
  }
};

export const fetchOrganizationMembers = (organizationId) => async (dispatch) => {
  try {
    const requestString = `/organizations/${organizationId}/members`;

    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_ORGANIZATION_MEMBERS, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot get members at the moment',
        err,
      },
    });
  }
};

export const fetchOrganizationInvitations = (organizationId) => async (dispatch) => {
  try {
    const requestString = `/organizations/${organizationId}/invitations`;

    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_ORGANIZATION_INVITATIONS, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot get invitations at the moment',
        err,
      },
    });
  }
};

export const fetchOrganizationAffiliates = (organizationId) => async (dispatch) => {
  try {
    const requestString = `/organizations/${organizationId}/affiliates`;
    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_ORGANIZATION_AFFILIATES, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot get organizations affiliates at the moment',
        err,
      },
    });
  }
};

export const fetchOrganizations = (properties) => async (dispatch) => {
  try {
    let {
      sortBy, limit, pageNumber, dataFilter,
    } = properties;

    if (!limit) limit = 10;
    if (!pageNumber) pageNumber = 1;
    if (!dataFilter) dataFilter = {};
    if (!sortBy) sortBy = 'createdAt:asc';

    const requestString = '/organizations/list';
    const res = await hypercube.get(requestString, {
      params: {
        dataFilter,
        sortBy,
        limit,
        pageNumber,
      },
    });

    dispatch({ type: FETCH_ORGANIZATIONS, payload: res.data });
  } catch (err) {
    if (err.response?.data?.errors) throw err.response.data.errors;
    else {
      dispatch({
        type: FEED_MODAL,
        payload: {
          type: 'fail',
          from: 'Organizations',
          err,
        },
      });
    }
  }
};

export const fetchDefaultOrganization = (organizationId) => async (dispatch) => {
  try {
    const res = await hypercube.patch(`/users/currentOrg/${organizationId}`);
    dispatch({ type: FETCH_DEFAULT_ORGANIZATION, payload: res.data });
  } catch (err) {
    if (err.response?.data?.errors) throw err.response.data.errors;
    else {
      dispatch({
        type: FEED_MODAL,
        payload: {
          type: 'fail',
          from: 'Organizations',
          err,
        },
      });
    }
  }
};

export const fetchOrganizationsEdges = () => async (dispatch) => {
  try {
    const requestString = '/organizations/edges';
    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_ORGANIZATION_EDGES, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'Organizations', err },
    });
  }
};

export const deleteOrganization = (id) => async (dispatch) => {
  try {
    const requestString = `/organizations/${id}`;
    await hypercube.delete(requestString);

    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'success',
        from: 'organization',
        vendor: 'organization successfully deleted',
      },
    });
    dispatch(fetchOrganizations({}));
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot delete organizations at the moment',
        err,
      },
    });
  }
};

export const updateOrganization = (properties) => async (dispatch) => {
  try {
    const {
      id, name, description, brandLogoUrl,
    } = properties;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('brandLogoUrl', brandLogoUrl);

    await hypercube.patch(`/organizations/${id}`, formData);

    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'success',
        from: 'organization',
        vendor: 'organization successfully updated',
      },
    });
    dispatch(fetchOrganizations({}));
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail',
        from: 'organization',
        vendor: 'Cannot updates organizations at the moment',
        err,
      },
    });
  }
};

export const switchCurrentOrganization = (organization, from = 'switchOrganization') => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DEFAULT_ORGANIZATION, payload: organization });

    dispatch(fetchOrganizations({}));
    dispatch(fetchDefaultOrganization(organization._id));
    dispatch(fetchOrganizationsEdges());

    // Savings
    dispatch(fetchSavings({ organizationId: organization._id }));

    // Integrations
    dispatch(fetchIntegrations({ organizationId: organization._id }));
    dispatch(fetchIntegrationsEdges({ organizationId: organization._id }));

    // Notifications
    dispatch(fetchNotifications({ organizationId: organization._id }));
    dispatch(fetchLatestNotifications({ organizationId: organization._id, latest: 8 }));
    dispatch(fetchNotificationsEdges({ organizationId: organization._id }));

    // Securities
    dispatch(fetchSecurities({ organizationId: organization._id }));

    // Transactions
    dispatch(fetchTransactions({ organizationId: organization._id, limit: 'all' }));
    dispatch(fetchSpendingTotal({ organizationId: organization._id }));
    dispatch(
      fetchTransactions({
        organizationId: organization._id,
        limit: 'all',
        dataFilter: {
          start: threeMonthsAgoDate,
          end: new Date(),
        },
        isChartData: true,
      }),
    );
    // TODO: uncomment these two statements when the endpoints are stable
    // dispatch(fetchExpensesByCategory({ organizationId: organization._id }));
    // dispatch(fetchExpensesByVendor({ organizationId: organization._id }));

    // Widgets
    dispatch(fetchAccounts({ organizationId: organization._id }));
    dispatch(fetchThreatsIdentified({ organizationId: organization._id }));
    dispatch(fetchThreatsActive({ organizationId: organization._id }));

    if (from === 'onboarding') {
      dispatch(fetchVendors());
    }
  } catch (err) {
    if (err) throw err;
    else {
      dispatch({
        type: FEED_MODAL,
        payload: {
          type: 'fail',
          from: 'Organizations',
          err,
        },
      });
    }
  }
};

export const updatePersonalFlag = (isPersonal) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORGANIZATION_PERSONAL_FLAG, payload: isPersonal });
  } catch (err) {
    if (err) throw err;
  }
};
