// Dependencies
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RegistrationLayout() {
  const currentStepNumber = useSelector((state) => state.onboarding.currentStep);
  const steps = useSelector((state) => state.onboarding.steps);
  const [currentStep, setCurrentStep] = useState();

  useEffect(() => {
    const stepName = Object.keys(steps)[currentStepNumber - 1];
    setCurrentStep(steps[stepName]);
  }, [currentStepNumber, steps]);

  // Get the current pathname
  const location = window.location.pathname;

  const isRegistrationRoute = location.startsWith('/registration');

  return (
    <>
      <Outlet />
      {isRegistrationRoute && <Navigate to={`/registration${currentStep?.route || '/complete-profile'}`} />}
    </>
  );
}

export default RegistrationLayout;
