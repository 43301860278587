// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..
import { fetchApiKeys } from '../../../actions/dashboard/apiKeysActions';
import { fetchIntegrations } from '../../../actions/dashboard/integrationsActions';
import { fetchNotifications } from '../../../actions/dashboard/notificationsActions';
import { fetchLastResources } from '../../../actions/dashboard/resourceActions';
import { fetchSavings } from '../../../actions/dashboard/savingsActions';
import { fetchSecurities } from '../../../actions/dashboard/securityActions';
import { fetchTransactions } from '../../../actions/spending/transactionsActions';
import { fetchOrganizationMembers, fetchOrganizations } from '../../../actions/organization/organizationActions';
import LineSpinner from '../../spinners/LineSpinner';
import TimeAgo from '../../widgets/TimeAgo';
import TableWrapper from './TableWrapper';

function Table({
  columns = [],
  items = [],
  model,
  total = 0,
  paginate = true,
  showFilter = true,
  dataFilter = {},
  searchFilterObject = { titleFilter: '', setTitleFilter: () => {} },
}) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const filter = useSelector((state) => state?.navigation[0]?.data?.dataFilter);
  let data = [];
  let fetchItems = null;
  let searchInstructions = '';

  const getTable = () => {
    switch (model) {
      case 'integrations':
        data = items.map((item) => ({
          integration: {
            icon: item.vendorIcon,
            integration: item.name,
          },
          actions: {
            id: item._id,
            name: item.name,
            description: item.description,
            isActive: item.isActive,
            userOrgs: user.organizations,
            organizationId: item.organizationId,
          },
          description: item.description,
          identifier: item.integrationIdentifier,
          status: item.isActive,
          updatedOn: item.updatedAt,
          createdOn: item.createdAt,
          lastStatusChange: item.lastStatusChange,
        }));
        fetchItems = fetchIntegrations;
        searchInstructions = t('components.common.table.integrationPlaceholder');
        break;
      case 'transactions':
        data = items.map((item) => ({
          vendor: {
            icon: item.vendorIcon,
            integration: item.integrationName,
          },
          category: item.category,
          spending: {
            spending: item.amount,
            currency: item.currencyUnit,
          },
          saving: item.savingAmount,
          currency: item.currencyUnit,
          purchased: item.invoiceTimestamp.start.split('', 10),
          updatedOn: item.updatedOn,
        }));
        fetchItems = fetchTransactions;
        searchInstructions = t('components.common.table.transactionPlaceholder');
        break;
      case 'notifications':
        data = items.map((item) => ({
          type: {
            notificationType: item.type,
            notificationIcon: item.icon,
          },
          description: item.description,
          createdAt: TimeAgo(item.createdAt),
        }));
        fetchItems = fetchNotifications;
        searchInstructions = t('components.common.table.notificationPlaceholder');
        break;
      case 'apiKeys':
        data = items.map((item) => ({
          name: item.name,
          date: item.date,
          active: item.active,
          createdAt: item.createdAt,
          permissions: item.permissions,
          actions: {
            accessKeyId: item.accessKeyId,
            active: item.active,
          },
        }));
        fetchItems = fetchApiKeys;
        break;
      case 'savings':
        data = items.map((item) => ({
          vendor: {
            icon: item.vendorIcon,
            integration: item.integration,
          },
          title: item.title,
          status: item.status,
          date: TimeAgo(item.updatedAt),
          priority: item.priority,
          saving: {
            amount: item.savingAmount,
          },
          actions: {
            id: item._id,
            title: item.title,
            icon: item.vendorIcon,
            instructions: item.instructions,
            knowledgeBase: item.knowledgeBase,
            vendor: item.vendorName,
            isActive: item.isActive,
            type: item.type,
            archived: item.archived,
            dataFilter: filter,
            integrationName: item.integration,
            integrationId: item.integrationId,
          },
        }));
        searchInstructions = t('components.common.table.savingPlaceholder');
        fetchItems = fetchSavings;
        break;
      case 'security':
        data = items.map((item) => ({
          vendor: {
            icon: item.vendorIcon,
            integration: item.integration,
          },
          title: item.title,
          status: item.status,
          date: TimeAgo(item.updatedAt),
          priority: item.priority,
          actions: {
            id: item._id,
            title: item.title,
            icon: item.vendorIcon,
            instructions: item.instructions,
            knowledgeBase: item.knowledgeBase,
            vendor: item.vendorName,
            isActive: item.isActive,
            type: item.type,
            archived: item.archived,
            dataFilter: filter,
            integrationName: item.integration,
            integrationId: item.integrationId,
          },
        }));
        searchInstructions = t('components.common.table.securityPlaceholder');
        fetchItems = fetchSecurities;
        break;
      case 'organizations':
        data = items.map((item) => ({
          description: item.description,
          date: TimeAgo(item.updatedAt),
          organization: {
            name: item.name,
            brandLogoUrl: item.brandLogoUrl,
          },
          actions: {
            id: item._id,
            name: item.name,
            brandLogoUrl: item.brandLogoUrl,
            organizationOwner: item.owner,
            organization: item,
          },
        }));
        searchInstructions = t('components.common.table.organizationPlaceholder');
        fetchItems = fetchOrganizations;
        break;
      case 'members':
        data = items.map((item) => ({
          member: {
            name: item.email,
            avatarUrl: item.avatarUrl,
          },
          role: item.role,
          actions: {
            id: item._id,
            organizationId: item.organizationId,
            organizationOwner: item.organizationOwner,
            name: item.email,
            role: item.role,
            userId: user._id,
            userOrgs: user.organizations,
          },
        }));
        searchInstructions = 'Search your members by name';
        fetchItems = fetchOrganizationMembers;
        break;
      case 'invitations':
        data = items.map((item) => ({
          invitation: {
            name: item.email,
          },
          status: item.status,
          role: item.roleName,
          actions: {
            name: item.email,
            organizationId: item.organizationId,
            invitationUrl: item.invitationUrl,
            id: item._id,
            userId: user._id,
            userOrgs: user.organizations,
          },
        }));
        searchInstructions = 'Search your members by name';
        fetchItems = fetchOrganizationMembers;
        break;
      case 'resources':
        data = items.map((item, i) => ({
          title: item.identifier,
          status: item.status,
          region: item.region,
          date: TimeAgo(item.updatedAt),
          actions: {
            id: item._id,
            content: item.content,
            archived: item.archived,
            title: item.identifier,
            dataFilter,
          },
        }));
        fetchItems = fetchLastResources;
        break;
      case 'affiliates':
        data = items.map((item) => ({
          member: {
            name: item.email,
            avatarUrl: item?.avatarUrl || 'https://cdn.gethypercube.com/images/vendors/hypercube-add-members.svg',
          },
          role: item?.role || item?.roleName,
          status: item?.status || 'active',
          actions: {
            id: item._id,
            organizationId: item.organizationId,
            organizationOwner: item?.organizationOwner,
            invitationUrl: item?.invitationUrl,
            name: item.email,
            role: item.role,
            userId: user._id,
            userOrgs: user.organizations,
            type: item.type,
          },
        }));
        break;
      default:
        break;
    }
    return (
      <TableWrapper
        columns={columns}
        data={data}
        total={total}
        getItems={fetchItems}
        model={model}
        searchFilterObject={searchFilterObject}
        paginate={paginate}
        showFilter={showFilter}
        dataFilter={dataFilter}
        searchInstructions={searchInstructions}
      />
    );
  };

  return (
    <div className="col-12 p-0">
      <div className="card" data-toggle="lists">
        {items ? (
          <>{getTable()}</>
        ) : (
          <LineSpinner name={t('components.common.table.spinners')} />
        )}
      </div>
    </div>
  );
}

Table.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  model: PropTypes.string.isRequired,
  searchFilterObject: PropTypes.shape({
    titleFilter: PropTypes.string,
    setTitleFilter: PropTypes.func,
  }),
  paginate: PropTypes.bool,
  showFilter: PropTypes.bool,
  dataFilter: PropTypes.shape({}),
};

export default Table;
