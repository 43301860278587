// eslint-disable-next-line import/prefer-default-export
export const hypercubeVendors = ['AWS', 'Azure', 'GCP', 'OCI'];

// Warning: No alter order
export const vendors = {
  AWS: 'Amazon Web Services',
  Azure: 'Microsoft Azure',
  GCP: 'Google Cloud',
  OCI: 'Oracle Cloud Infrastructure',
};
