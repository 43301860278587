// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, assets, actions etc..
import parse from 'html-react-parser';
import AWSIntegrationForm from '../../forms/AWSIntegrationForm';
import GoogleIntegrationForm from '../../forms/GoogleIntegrationForm';
import OracleIntegrationForm from '../../forms/OracleIntegrationForm';
import CloseModalButton from '../CloseModalButton';

function IntegrationModal(props) {
  const { t } = useTranslation();
  const { content, closeModal } = props;
  const renderModalContent = (items) => items.map((item) => (
    <li className="m-0 py-1 px-1" key={item}>
      {parse(item)}
    </li>
  ));

  const renderIntegrationForm = (id, vendor) => {
    switch (vendor) {
      case 'Amazon Web Services':
        return <AWSIntegrationForm id={id} vendor={vendor} />;
      case 'Google Cloud':
        return <GoogleIntegrationForm id={id} vendor={vendor} />;
      case 'Oracle Cloud Infrastructure':
        return <OracleIntegrationForm id={id} vendor={vendor} />;
      default:
        return <AWSIntegrationForm id={id} vendor={vendor} />;
    }
  };

  return (
    <>
      <div className="modal-header border-0 align-items-start">
        <div className="w-100 d-flex flex-column align-items-center">
          <img src={content.icon} alt="Logo" height="70" width="70" />
          <h6 className="header-pretitle mb-1">
            {t('components.modals.integrations.integrationModal.instructions')}
          </h6>
          <h1 className="header-title">{content.vendor}</h1>
        </div>
        <CloseModalButton closeModal={closeModal} />
      </div>
      <div className="modal-body d-flex flex-column p-3">
        <ol className="m-0">{renderModalContent(content.instructions)}</ol>
        {content.instructions.length > 1
          ? renderIntegrationForm(content.id, content.vendor)
          : null}
      </div>
    </>
  );
}

IntegrationModal.propTypes = {
  content: PropTypes.shape({
    instructions: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    icon: PropTypes.string,
    vendor: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default IntegrationModal;
